import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '../Drawer';

import './style.css';

function SidebarNavigation(props) {
  return (
    <div onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <Drawer
        anchor="right"
        contentClassName={
          props.open
            ? 'right-sidebar-navigation__content right-sidebar-navigation__content-open'
            : 'right-sidebar-navigation__content'
        }
        overlayClassName="right-sidebar-navigation__overlay"
        // open={props.open}
        persistent
      >
        {props.children}
      </Drawer>
    </div>
  );
}

SidebarNavigation.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default SidebarNavigation;
