import React, { useContext } from 'react';
import moment from 'moment';
import ArchivedAttachmentRow from '../ArchivedAttachmentRow';
import getNestedObject from '../../utils/getNestedObject';
import { retrieveFile } from '../../state/resources/files/actions';
import { appActionContext } from '../App/contexts';
import generateAttachmentDisplayName from '../../utils/generateAttachmentDisplayName';
import Stop from '../../_blessed/store/entities/stops/model';
import { getDepartmentDisplayName } from '../../utils/data-processing/department';
import { Leg } from '../../_blessed/components/features/Dispatch/store/legs/model';
import { DepartmentsById } from '../../_blessed/store/entities/departments/types';
import { Attachment } from '../../_blessed/components/features/Dispatch/store/attachments/model';

import './style.css';

interface SidebarDocumentsArchiveProps {
  leg?: Leg;
  attachments: Attachment[];
  order?: any;
  legsByOrderUUID?: Record<string, Leg[]>;
  department?: any;
  departmentsById?: DepartmentsById;
  stopsByLegUUID?: Record<string, Stop[]>;
  // eslint-disable-next-line no-unused-vars
  onNameClick: (...args: any[]) => any;
}

function SidebarDocumentsArchive(props: SidebarDocumentsArchiveProps) {
  const executeAppAction = useContext(appActionContext);
  const baseClassName = 'sidebar-documents-archive';
  const className = baseClassName;

  const orderedAttachments = props.attachments.sort((a, b) => {
    if (moment(a.deletedAt).isAfter(b.deletedAt)) {
      return -1;
    }

    return 1;
  });

  return (
    <div className={className}>
      <div className={`${baseClassName}__title`}>Archive (Deleted documents)</div>
      {!props.attachments.length && <div>No deleted documents.</div>}
      {orderedAttachments.map((attachment) => {
        const attachmentUUID = attachment.uuid;
        const attachmentAttributes = attachment;

        const { file } = attachment;

        if (file) {
          const fileUUID = file.uuid;
          const fileAttributes = file;

          let filename;
          let departmentDisplayName;
          switch (attachmentAttributes.ownerType) {
            case 'order': {
              filename = `order-${props.order.attributes.orderNumber}-${attachmentAttributes.type}-${attachmentUUID}`;
              departmentDisplayName = 'N/A';
              break;
            }
            case 'department': {
              filename = `department-${props.department.uuid}-${attachmentAttributes.type}-${attachmentUUID}`;
              departmentDisplayName =
                getNestedObject(props.department, ['attributes', 'nickname']) ||
                getNestedObject(props.department, ['attributes', 'name']) ||
                'N/A';
              break;
            }
            case 'stop': {
              const legNumber = props.leg?.legNumber;
              let foundLeg: Leg | undefined;
              // This means we're looking from the order sidebar. So we need to find the leg this stop attachment belongs to
              if (!legNumber) {
                const orderLegs = props.legsByOrderUUID?.[props.order.id];
                foundLeg = orderLegs?.find((leg) => {
                  const legStops = props.stopsByLegUUID?.[leg.uuid];
                  const foundStop = legStops?.find((stop) => stop.uuid === attachmentAttributes.ownerUUID);

                  if (foundStop) {
                    return leg;
                  }

                  return false;
                });
                filename = `leg-${foundLeg?.legNumber}-${attachmentAttributes.type}-${attachmentUUID}`;
              } else {
                filename = `leg-${legNumber}-${attachmentAttributes.type}-${attachmentUUID}`;
              }

              let carrierId;
              if (legNumber) {
                carrierId = props.leg?.carrierUUID;
              } else {
                carrierId = foundLeg?.carrierUUID;
              }

              const department = (carrierId && props.departmentsById?.[carrierId]) || null;
              departmentDisplayName = getDepartmentDisplayName(department) || 'N/A';
              break;
            }
            default:
              filename = 'unknown-file';
              break;
          }

          return (
            <ArchivedAttachmentRow
              key={attachmentUUID}
              attachment={attachment}
              displayName={generateAttachmentDisplayName(attachmentAttributes)}
              filename={filename}
              file={file}
              departmentDisplayName={departmentDisplayName}
              onNameClick={props.onNameClick}
              onGetImage={() =>
                executeAppAction(retrieveFile(fileAttributes.type, fileUUID, attachmentAttributes.name))
              }
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export default React.memo(SidebarDocumentsArchive);
