import React from 'react';

function SplitIcon(props) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.2 10.3V8.2H9.1V14.5H7.7V0.5H9.1V6.8H11.2V4.7L14 7.5L11.2 10.3ZM0 7.5L2.8 10.3V8.2H4.9V14.5H6.3V0.5H4.9V6.8H2.8V4.7L0 7.5Z" />
    </svg>
  );
}

export default SplitIcon;
