import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function SidebarHeader(props) {
  return (
    <div className="right-sidebar-header__wrapper">
      <div className="right-sidebar-header__top">
        <div className="right-sidebar-header__title">{props.title}</div>
        <div className="right-sidebar-header__right-text">{props.createdOn ? `Created On ${props.createdOn}` : ''}</div>
      </div>
      <div className="right-sidebar-header__right">
        <div className="right-sidebar-header__subtitle">{props.subtitle}</div>
        <div className="right-sidebar-header__right-icon">{props.right}</div>
      </div>
    </div>
  );
}

SidebarHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,
  right: PropTypes.any,
  createdOn: PropTypes.string
};

export default SidebarHeader;
