// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --right-sidebar-navigation-closed-width: 61px;
}

.right-sidebar-navigation__content {
    top: var(--sidebar-header-height);
    height: calc(100vh - var(--sidebar-header-height));
    flex-direction: column;
    background-color: var(--theme-1-bg-color-0);
    z-index: 100;
    transform: translate3d(calc(100% - var(--right-sidebar-navigation-closed-width)), 0, 0);
  }

.right-sidebar-navigation__content-open {
      transform: translate3d(0, 0, 0);
    }

.right-sidebar-navigation__overlay {
    top: var(--sidebar-header-height);
    height: calc(100vh - var(--sidebar-header-height));
    z-index: 100;
  }`, "",{"version":3,"sources":["webpack://./app/components/SidebarNavigation/style.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;AAC/C;;AAGE;IACE,iCAAiC;IACjC,kDAAkD;IAClD,sBAAsB;IACtB,2CAA2C;IAC3C,YAAY;IACZ,uFAAuF;EAKzF;;AAHE;MACE,+BAA+B;IACjC;;AAGF;IACE,iCAAiC;IACjC,kDAAkD;IAClD,YAAY;EACd","sourcesContent":[":root {\n  --right-sidebar-navigation-closed-width: 61px;\n}\n\n.right-sidebar-navigation {\n  &__content {\n    top: var(--sidebar-header-height);\n    height: calc(100vh - var(--sidebar-header-height));\n    flex-direction: column;\n    background-color: var(--theme-1-bg-color-0);\n    z-index: 100;\n    transform: translate3d(calc(100% - var(--right-sidebar-navigation-closed-width)), 0, 0);\n\n    &-open {\n      transform: translate3d(0, 0, 0);\n    }\n  }\n\n  &__overlay {\n    top: var(--sidebar-header-height);\n    height: calc(100vh - var(--sidebar-header-height));\n    z-index: 100;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
