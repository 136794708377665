// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-icon-btn {
  border-radius: 3px;
  padding: 4px;
}

  .r-icon-btn * {
    width: 14px;
    height: 14px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/IconButton/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AAMd;;EAJE;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".r-icon-btn {\n  border-radius: 3px;\n  padding: 4px;\n\n  & * {\n    width: 14px;\n    height: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
