import React from 'react';
import PropTypes from 'prop-types';
import InfoCircleIcon from '@components/deprecatedTookit/icons/fa/solid/InfoCircleIcon';
import ExclamationCircleIcon from '@components/deprecatedTookit/icons/fa/solid/ExclamationCircleIcon';
import ExclamationTriangleIcon from '@components/deprecatedTookit/icons/fa/solid/ExclamationTriangleIcon';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/solid/CheckCircleIcon';
import LightbulbIcon from '@components/deprecatedTookit/icons/fa/solid/LightbulbIcon';
import Tooltip from '../Tooltip';

import './style.css';

function SystemTooltip(props) {
  const className = 'r-system-tooltip';
  const rowClassName = 'r-system-tooltip__row';
  const rowTitleClassName = 'r-system-tooltip__row-title';
  let Icon = null;

  return (
    <Tooltip id={props.id} place={props.place || 'top'}>
      <div className={className}>
        {props.items &&
          props.items.map((item, index) => {
            let iconClassName = 'r-system-tooltip__row-icon';

            switch (item.type) {
              case 'info':
                Icon = InfoCircleIcon;
                iconClassName += ' r-system-tooltip__row-icon--info';
                break;
              case 'error':
                Icon = ExclamationCircleIcon;
                iconClassName += ' r-system-tooltip__row-icon--error';
                break;
              case 'warn':
                Icon = ExclamationTriangleIcon;
                iconClassName += ' r-system-tooltip__row-icon--warn';
                break;
              case 'success':
                Icon = CheckCircleIcon;
                iconClassName += ' r-system-tooltip__row-icon--success';
                break;
              case 'idle':
                Icon = LightbulbIcon;
                iconClassName += ' r-system-tooltip__row-icon--idle';
                break;
              default:
            }

            return (
              <div key={item.message || index} className={rowClassName}>
                {Icon && <Icon className={iconClassName} />}
                {item.title && <span className={rowTitleClassName}>{item.title}</span>}
                {item.message && <span>{item.message}</span>}
              </div>
            );
          })}
      </div>
    </Tooltip>
  );
}

SystemTooltip.propTypes = {
  place: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.any
};

export default SystemTooltip;
