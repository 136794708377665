import React from 'react';
import TimesIcon from '@components/deprecatedTookit/icons/fa/light/TimesIcon';

export interface ITagProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  index: number;
  // eslint-disable-next-line react/no-unused-prop-types
  groupName?: any;
  className?: any;
  label: string;
  prefix?: string;
  onClick?: any;
  onDelete?: (event: ITagProps) => any;
}

function Tag(props: ITagProps) {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props);
    }
  };

  const handleDeleteClick = (event: any) => {
    event.stopPropagation();

    if (props.onDelete) {
      props.onDelete(props);
    }
  };

  let className = 'r-tag';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className} onClick={handleClick || null}>
      {props.prefix && <span className="r-tag__label-prefix">{props.prefix}</span>}
      {props.label && <span className="r-tag__label">{props.label}</span>}
      {props.onDelete && <TimesIcon className="r-tag__icon" onClick={handleDeleteClick} />}
    </div>
  );
}

export default Tag;
