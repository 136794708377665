import React, { useContext, useState } from 'react';
import useKonami from '@client/utils/hooks/useKonami';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { appContext } from '../App/contexts';
import getNestedObject from '../../utils/getNestedObject';
import Avatar from '../Avatar';
import manifest from '../../manifest.json';

import './style.css';

function ProfileInfo() {
  const { state: appState } = useContext(appContext);
  const userInfo = appState['auth.userInfo'];
  const [harvey, setHarvey] = useState(false);
  const firstName = getNestedObject(userInfo, ['firstName']) || '';
  const lastName = getNestedObject(userInfo, ['lastName']) || '';
  const firstNameInitial = firstName ? firstName[0] : '';
  const lastNameInitial = lastName ? lastName[0] : '';
  let imagePath;
  const { showVersion } = useFlags();

  if (harvey) {
    imagePath = 'https://d1xcdyhu7q1ws8.cloudfront.net/wp-content/uploads/2017/10/24114043/steve-harvey-face.png';
  }

  useKonami(() => {
    setHarvey(!harvey);
  });

  const profilePicture = <Avatar imagePath={imagePath} firstInitial={firstNameInitial} lastInitial={lastNameInitial} />;

  const commitHash = manifest.commitHash || '';

  return (
    <div className="profile-info-wrapper">
      {profilePicture}
      <div className="profile-info-right">
        <p className="profile-info-fullname">{`${firstName} ${lastName}`}</p>
        {showVersion ? <p className="version-info">v: {commitHash.slice(0, 8)}</p> : null}
      </div>
    </div>
  );
}

export default ProfileInfo;
