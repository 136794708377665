// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-system-tooltip {
  font-weight: 500;
}

  .r-system-tooltip > * {
    margin-top: 6px;
  }

  .r-system-tooltip > *:first-child {
      margin-top: 0;
    }

  .r-system-tooltip__row {
    display: flex;
    line-height: 14px;
  }

  .r-system-tooltip__row > * {
      margin-left: 6px;
    }

  .r-system-tooltip__row > *:first-child {
        margin-left: 0;
      }

  .r-system-tooltip__row-title {
      font-weight: 500;
    }

  /* icon */

  .r-system-tooltip__row-icon {
      width: 14px;
      height: 14px;
    }

  /* IDLE */

  .r-system-tooltip__row-icon--idle {
        color: #fbfbfb;
      }

  /* INFO */

  .r-system-tooltip__row-icon--info {
        color: #00b0ff;
      }

  /* ERROR */

  .r-system-tooltip__row-icon--error {
        color: #ff1544;
      }

  /* WARN */

  .r-system-tooltip__row-icon--warn {
        color: #fffe80;
      }

  /* SUCCESS */

  .r-system-tooltip__row-icon--success {
        color: #02c853;
      }
`, "",{"version":3,"sources":["webpack://./app/components/SystemTooltip/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAyDlB;;EAvDE;IACE,eAAe;EAKjB;;EAHE;MACE,aAAa;IACf;;EAGF;IACE,aAAa;IACb,iBAAiB;EA4CnB;;EA1CE;MACE,gBAAgB;IAKlB;;EAHE;QACE,cAAc;MAChB;;EAGF;MACE,gBAAgB;IAClB;;EAEA,SAAS;;EACT;MACE,WAAW;MACX,YAAY;IA0Bd;;EAxBE,SAAS;;EACT;QACE,cAAc;MAChB;;EAEA,SAAS;;EACT;QACE,cAAc;MAChB;;EAEA,UAAU;;EACV;QACE,cAAc;MAChB;;EAEA,SAAS;;EACT;QACE,cAAc;MAChB;;EAEA,YAAY;;EACZ;QACE,cAAc;MAChB","sourcesContent":[".r-system-tooltip {\n  font-weight: 500;\n\n  & > * {\n    margin-top: 6px;\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n\n  &__row {\n    display: flex;\n    line-height: 14px;\n\n    & > * {\n      margin-left: 6px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n    }\n\n    &-title {\n      font-weight: 500;\n    }\n\n    /* icon */\n    &-icon {\n      width: 14px;\n      height: 14px;\n\n      /* IDLE */\n      &--idle {\n        color: #fbfbfb;\n      }\n\n      /* INFO */\n      &--info {\n        color: #00b0ff;\n      }\n\n      /* ERROR */\n      &--error {\n        color: #ff1544;\n      }\n\n      /* WARN */\n      &--warn {\n        color: #fffe80;\n      }\n\n      /* SUCCESS */\n      &--success {\n        color: #02c853;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
