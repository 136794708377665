// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--color-green-8);
  height: 32px;
  border-radius: 24px;
  padding: 5px 12px;
}

  .r-tag.excluded {
    background-color: var(--color-red-4);
  }

  .r-tag__icon {
    margin-left: 8px;
    height: 14px;
    cursor: pointer;
  }

  .r-tag__label {
    margin-left: 4px;
    font-weight: 500;
  }

  .r-tag__label:first-child {
      margin-left: 0;
    }
`, "",{"version":3,"sources":["webpack://./app/components/Tag/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,sCAAsC;EACtC,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AAoBnB;;EAlBE;IACE,oCAAoC;EACtC;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;EAKlB;;EAHE;MACE,cAAc;IAChB","sourcesContent":[".r-tag {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  background-color: var(--color-green-8);\n  height: 32px;\n  border-radius: 24px;\n  padding: 5px 12px;\n\n  &.excluded {\n    background-color: var(--color-red-4);\n  }\n\n  &__icon {\n    margin-left: 8px;\n    height: 14px;\n    cursor: pointer;\n  }\n\n  &__label {\n    margin-left: 4px;\n    font-weight: 500;\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
