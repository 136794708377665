// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-table-body-cell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 13px 25px 13px 0;
  min-width: 0;
  border-bottom: 1px solid var(--color-grayscale-2);
  background-color: var(--color-grayscale-1);
}
  

  .r-table-body-cell > * {
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  

  .r-table-body-cell--first {
    padding-left: 30px;
  }
  

  .r-table-body-cell-locked {
    background-color: var(--color-grayscale-0);
    border-bottom: 1px solid var(--color-grayscale-4);
    font-weight: 500;
  }
  

  .r-table-body-cell-locked--last {
      border-right: 1px solid var(--color-grayscale-4);
      border-bottom: 1px solid var(--color-grayscale-4);
    }
  

  .r-table-body-cell-icon-cell {
    padding: 13px 0;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Table/components/TableBodyCell/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,iDAAiD;EACjD,0CAA0C;AA2B5C;;;EAxBE;IACE,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;EACzB;;;EAEA;IACE,kBAAkB;EACpB;;;EAEA;IACE,0CAA0C;IAC1C,iDAAiD;IACjD,gBAAgB;EAMlB;;;EAJE;MACE,gDAAgD;MAChD,iDAAiD;IACnD;;;EAGF;IACE,eAAe;EACjB","sourcesContent":[".r-table-body-cell {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 13px 25px 13px 0;\n  min-width: 0;\n  border-bottom: 1px solid var(--color-grayscale-2);\n  background-color: var(--color-grayscale-1);\n  \n\n  & > * {\n    min-width: 0;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  &--first {\n    padding-left: 30px;\n  }\n\n  &-locked {\n    background-color: var(--color-grayscale-0);\n    border-bottom: 1px solid var(--color-grayscale-4);\n    font-weight: 500;\n\n    &--last {\n      border-right: 1px solid var(--color-grayscale-4);\n      border-bottom: 1px solid var(--color-grayscale-4);\n    }\n  }\n\n  &-icon-cell {\n    padding: 13px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
