import React from 'react';
import Button, { IButtonProps } from '../Button';

import './style.css';

export interface IconButtonProps extends IButtonProps {}

function IconButton(props: IconButtonProps) {
  const baseClassName = 'r-icon-btn';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return <Button {...props} className={className} />;
}

export default IconButton;
