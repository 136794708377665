import React from 'react';
import PropTypes from 'prop-types';
import SortAmountDownIcon from '@components/deprecatedTookit/icons/fa/regular/SortAmountDownIcon';
import SortAmountUpIcon from '@components/deprecatedTookit/icons/fa/regular/SortAmountUpIcon';
import TextInput from '../../../TextInput';
import EditableField from '../../../EditableField';

import './style.css';

function TableHeaderFieldCol(props) {
  const isSorting = props.sortable && props.sortBy && props.sortBy.indexOf(props.name) >= 0;

  let className = 'r-table-header-field-col';

  if (props.isFirstField) {
    className += ' r-table-header-field-col--first';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  let sortIcon = null;

  if (isSorting) {
    sortIcon =
      props.sortBy[0] === '-' ? (
        <SortAmountUpIcon className="r-table-header-field-col__sort-icon" />
      ) : (
        <SortAmountDownIcon className="r-table-header-field-col__sort-icon" />
      );
  }

  let searchableComponent = (
    <div onClick={(e) => e.stopPropagation()}>
      <TextInput
        disableAutoComplete
        className="r-table-header-field-col__filter-input"
        value={props.filterValue}
        onChange={(value) => {
          props.onFilterChange(value.trimStart(), props.searchable && props.searchable.inputProps);
        }}
        tabIndex={props.filterTabIndex}
        data-testid={props['filter-data-testid']}
      />
    </div>
  );

  if (typeof props.searchable === 'object') {
    switch (props.searchable.type) {
      case 'dropdown':
        searchableComponent = (
          <div
            className="r-table-header-field-col__searchable-component"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <EditableField
              type="dropdown"
              className="r-table-header-field-col__filter-dropdown"
              editable
              value={props.searchable.inputProps.values}
              inputProps={{
                value: props.searchable.inputProps.value,
                labelField: props.searchable.inputProps.labelField || 'label',
                valueField: props.searchable.inputProps.valueField || 'id',
                dropdownHandle: true,
                options: props.searchable.inputProps.options,
                closeOnSelect: true
              }}
              displayFormatter={props.searchable.inputProps.displayFormatter}
              onChange={(value) => {
                if (props.onFilterChange) {
                  props.onFilterChange(value);
                }
              }}
            />
          </div>
        );
        break;
      case 'date':
        searchableComponent = (
          <div
            className="r-table-header-field-col__searchable-component"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <EditableField
              type="date"
              className="r-table-header-field-col__filter-dropdown"
              editable
              // inputProps={{
              //   labelField: 'label',
              //   valueField: 'id',
              //   dropdownHandle: true,
              //   options: props.searchable.inputProps.options,
              //   closeOnSelect: true
              // }}
              displayFormatter={props.searchable.displayFormatter}
              onChange={(value) => {
                if (props.onFilterChange) {
                  props.onFilterChange(value);
                }
              }}
            />
          </div>
        );
        break;
      default:
    }
  }

  return (
    <div className={className}>
      {props.title && (
        <div className={`r-table-header-field-col__title ${props.className ? props.className : ''}`}>
          <span className="r-table-header-field-col__title-text">{props.title}</span>
          {sortIcon}
        </div>
      )}
      {props.searchable && searchableComponent}
      {props.children}
    </div>
  );
}

TableHeaderFieldCol.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  isFirstField: PropTypes.bool,
  sortable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  filterValue: PropTypes.string,
  searchable: PropTypes.any,
  filterTabIndex: PropTypes.number,
  onFilterChange: PropTypes.func,
  'filter-data-testid': PropTypes.string
};

export default TableHeaderFieldCol;
