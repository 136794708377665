import React from 'react';
import PropTypes from 'prop-types';
import PhoneIcon from '@components/deprecatedTookit/icons/fa/regular/PhoneIcon';
import copy from 'clipboard-copy';
import { logAnalyticsClick } from '@client/utils/analytics';
import IconButton from '../IconButton';
import Toaster, { raiseToast } from '../Toaster/component';
import SystemToast from '../SystemToast/component';
import SystemTooltip from '../SystemTooltip';

import './style.css';

function PhoneButton(props) {
  const baseClassName = 'PhoneButton';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleClick = async (e) => {
    logAnalyticsClick('Phone Button Click', props.screen);
    e.stopPropagation();

    if (props.copyValue) {
      copy(props.copyValue);
      raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Copied to clipboard" />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
    }

    if (props.onClick) {
      props.onClick();
    }
  };

  const handleHover = async (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <IconButton
        className={className}
        icon={<PhoneIcon data-tooltip-id={`phone-btn-${props.id}`} />}
        onClick={handleClick}
        onHover={handleHover}
      />
      <SystemTooltip
        id={`phone-btn-${props.id}`}
        place="top"
        items={[
          {
            message: props.toolTipValue || 'N/A'
          }
        ]}
      />
    </>
  );
}

PhoneButton.propTypes = {
  id: PropTypes.string,
  screen: PropTypes.string,
  className: PropTypes.string,
  copyValue: PropTypes.string,
  toolTipValue: PropTypes.string,
  onClick: PropTypes.func
};

export default PhoneButton;
