// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-toast {
  display: flex;
  flex: 1;
  background: #363636;
  padding: 9px 0 10px 14px;
  color: var(--color-grayscale-0);
  align-items: center;
  min-height: 50px;
}

  .system-toast > p {
    display: flex;
    flex: 1;
    line-height: 16px;
    margin: 0;
    margin-right: 14px;
  }

  /* icon */

  .system-toast__icon {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }

  /* INFO */

  .system-toast__icon--info {
      color: var(--color-blue-6);
    }

  /* ERROR */

  .system-toast__icon--error {
      color: red;
    }

  /* WARN */

  .system-toast__icon--warn {
      color: yellow;
    }

  /* SUCCESS */

  .system-toast__icon--success {
      color: #41a741;
    }

  /* actions */

  .system-toast__actions {
    display: flex;
    align-items: center;
    margin-right: 14px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/SystemToast/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;EACnB,wBAAwB;EACxB,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;AA2ClB;;EAzCE;IACE,aAAa;IACb,OAAO;IACP,iBAAiB;IACjB,SAAS;IACT,kBAAkB;EACpB;;EAEA,SAAS;;EACT;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EAqBpB;;EAnBE,SAAS;;EACT;MACE,0BAA0B;IAC5B;;EAEA,UAAU;;EACV;MACE,UAAU;IACZ;;EAEA,SAAS;;EACT;MACE,aAAa;IACf;;EAEA,YAAY;;EACZ;MACE,cAAc;IAChB;;EAGF,YAAY;;EACZ;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".system-toast {\n  display: flex;\n  flex: 1;\n  background: #363636;\n  padding: 9px 0 10px 14px;\n  color: var(--color-grayscale-0);\n  align-items: center;\n  min-height: 50px;\n\n  & > p {\n    display: flex;\n    flex: 1;\n    line-height: 16px;\n    margin: 0;\n    margin-right: 14px;\n  }\n\n  /* icon */\n  &__icon {\n    width: 20px;\n    height: 20px;\n    margin-right: 14px;\n\n    /* INFO */\n    &--info {\n      color: var(--color-blue-6);\n    }\n\n    /* ERROR */\n    &--error {\n      color: red;\n    }\n\n    /* WARN */\n    &--warn {\n      color: yellow;\n    }\n\n    /* SUCCESS */\n    &--success {\n      color: #41a741;\n    }\n  }\n\n  /* actions */\n  &__actions {\n    display: flex;\n    align-items: center;\n    margin-right: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
