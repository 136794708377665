import React from 'react';
import PropTypes from 'prop-types';
import TimesIcon from '@components/deprecatedTookit/icons/fa/regular/TimesIcon';
import Drawer from '../Drawer';

import './style.css';

function RightSidebar(props) {
  let contentClassName = 'right-sidebar__drawer-content';
  const overlayClassName = 'right-sidebar__drawer-overlay';

  if (props.open) {
    contentClassName += ' right-sidebar__drawer-content--open';
  }

  return (
    <Drawer
      fullscreen
      overlay
      anchor="right"
      contentClassName={contentClassName}
      overlayClassName={overlayClassName}
      open={props.open}
      disabled={props.disabled}
      disabledClose
      onClose={props.onClose}
      onClosed={props.onClosed}
    >
      {props.showCloseButton && (
        <div className="right-sidebar__close-btn" onClick={props.onClose}>
          <TimesIcon />
        </div>
      )}
      {props.component}
    </Drawer>
  );
}

RightSidebar.propTypes = {
  component: PropTypes.any,
  open: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onClosed: PropTypes.func
};

export default React.memo(RightSidebar);
