import React from 'react';
// import history from '../../utils/history'
// import { logPageView } from '../../utils/analytics';

export interface HistoryProps {}

function History() {
  // useEffect(() => {
  //   logPageView();

  //   history.listen(() => {
  //     logPageView();
  //   });
  // }, []);

  return null;
}

export default React.memo(History);
