// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-table-header-field-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-grayscale-6);
  padding: 15px 25px 0 0;
  width: 100%;
  height: 100%;
}

  .r-table-header-field-col--first{
      padding-left: 30px;
  }

  .r-table-header-field-col__title {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
  }

  .r-table-header-field-col__sortable {
    margin-top: 10px;
  }

  .r-table-header-field-col__sort-icon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }

  .r-table-header-field-col__searchable-component {
    width: 100%;
  }

  .r-table-header-field-col__filter-input {
    margin: 8px 0;
    width: 100%;
  }

  .r-table-header-field-col__filter-dropdown {
    margin: 8px 0;
    width: 100%;
    height: 24px !important;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Table/components/TableHeaderFieldCol/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,+BAA+B;EAC/B,sBAAsB;EACtB,WAAW;EACX,YAAY;AAqCd;;EAnCE;MACI,kBAAkB;EACtB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,WAAW;EACb;;EAEA;IACE,aAAa;IACb,WAAW;IACX,uBAAuB;EACzB","sourcesContent":[".r-table-header-field-col {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  color: var(--color-grayscale-6);\n  padding: 15px 25px 0 0;\n  width: 100%;\n  height: 100%;\n\n  &--first{\n      padding-left: 30px;\n  }\n\n  &__title {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    line-height: 14px;\n  }\n\n  &__sortable {\n    margin-top: 10px;\n  }\n\n  &__sort-icon {\n    width: 14px;\n    height: 14px;\n    margin-left: 5px;\n  }\n\n  &__searchable-component {\n    width: 100%;\n  }\n\n  &__filter-input {\n    margin: 8px 0;\n    width: 100%;\n  }\n\n  &__filter-dropdown {\n    margin: 8px 0;\n    width: 100%;\n    height: 24px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
