// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-file-select-zone {
  display: flex;
  flex-direction: column;
}

  .app-file-select-zone__dropdown-row {
    display: flex;
    align-items: center;
  }

  .app-file-select-zone__dropdown-row-dd {
      margin-left: 21px;
    }

  .app-file-select-zone__file-select-zone {
    margin-top: 10px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/NewDocumentUploader/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AAcxB;;EAZE;IACE,aAAa;IACb,mBAAmB;EAKrB;;EAHE;MACE,iBAAiB;IACnB;;EAGF;IACE,gBAAgB;EAClB","sourcesContent":[".app-file-select-zone {\n  display: flex;\n  flex-direction: column;\n\n  &__dropdown-row {\n    display: flex;\n    align-items: center;\n\n    &-dd {\n      margin-left: 21px;\n    }\n  }\n\n  &__file-select-zone {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
