import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import EditableField from '../EditableField';

import './style.css';

function Instruction({ dataField, dataResourceType, dataId, onSave, label, isAccount, defaultText, stopText }) {
  const className = 'department-detail-sidebar-sop-driver-instruction';

  const [switchToggle, setSwitchToggle] = React.useState(isAccount || stopText !== null);
  const [textFieldText, setTextfieldText] = React.useState(stopText === null ? defaultText : stopText);
  const onTextfieldSave = React.useCallback(
    (value, inputProps) => {
      setTextfieldText(value);

      if (onSave) {
        onSave(value, inputProps);
      }
    },
    [textFieldText, onSave]
  );

  return (
    <>
      {!isAccount && (
        <div className={`${className}__custom-input-switch`}>
          <div>Override</div>
          <Switch
            onChange={() => {
              if (switchToggle) {
                setTextfieldText(defaultText);
                onSave(null, { dataId, dataResourceType, dataField });
              } else if (!switchToggle) {
                setTextfieldText('');
                onSave('', { dataId, dataResourceType, dataField });
              }

              setSwitchToggle(!switchToggle);
            }}
            checked={switchToggle}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#24b248"
            offColor="#c6cbd4"
            width={32}
            height={16}
          />
        </div>
      )}
      <EditableField
        type="textarea"
        editable={switchToggle}
        label={label}
        className={`${className}__multi-input-row`}
        value={textFieldText || ''}
        onSave={onTextfieldSave}
        dataField={dataField}
        dataResourceType={dataResourceType}
        dataId={dataId}
      />
    </>
  );
}

Instruction.propTypes = {
  dataField: PropTypes.string,
  dataResourceType: PropTypes.string,
  dataId: PropTypes.string,
  onSave: PropTypes.func,
  label: PropTypes.string,
  isAccount: PropTypes.bool,
  defaultText: PropTypes.string,
  stopText: PropTypes.string
};

export default Instruction;
