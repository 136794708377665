// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-documents-archive {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0;
  padding: 25px 0;
  border-top: solid 1px var(--color-grayscale-3);
}

  .sidebar-documents-archive__title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 14px;
  }`, "",{"version":3,"sources":["webpack://./app/components/SidebarDocumentsArchive/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,eAAe;EACf,8CAA8C;AAOhD;;EALE;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".sidebar-documents-archive {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin: 25px 0;\n  padding: 25px 0;\n  border-top: solid 1px var(--color-grayscale-3);\n\n  &__title {\n    font-size: 14px;\n    font-weight: 500;\n    margin-bottom: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
