import React from 'react';
import SystemTooltip from '../SystemTooltip';

import './style.css';

interface LetterBadgeProps {
  text: string;
  className?: string;
  toolTipId: string;
  toolTipMessage: string;
  'aria-label'?: string;
}

function LetterBadge(props: LetterBadgeProps) {
  const baseClassName = 'letter-badge';
  let className = baseClassName;
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div className={className} data-tooltip-id={props.toolTipId} aria-label={props['aria-label']}>
      {props.text}
      <SystemTooltip id={props.toolTipId} items={[{ message: props.toolTipMessage }]} />
    </div>
  );
}

export default React.memo(LetterBadge);
