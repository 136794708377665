// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-loading-spinner {
  animation: r-loading-spinner-rotate 2s linear infinite;
  transform-origin: center center;
}

  .r-loading-spinner > circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: r-loading-spinner-dash 1.5s ease-in-out infinite, r-loading-spinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

@keyframes r-loading-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes r-loading-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes r-loading-spinner-color {
  100%,
  0% {
    stroke: var(--color-green-8);
  }
}
`, "",{"version":3,"sources":["webpack://./app/components/LoadingSpinner/style.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;EACtD,+BAA+B;AAQjC;;EANE;IACE,wBAAwB;IACxB,oBAAoB;IACpB,4GAA4G;IAC5G,qBAAqB;EACvB;;AAGF;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,wBAAwB;IACxB,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;IACzB,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;AACF;;AAEA;EACE;;IAEE,4BAA4B;EAC9B;AACF","sourcesContent":[".r-loading-spinner {\n  animation: r-loading-spinner-rotate 2s linear infinite;\n  transform-origin: center center;\n\n  & > circle {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n    animation: r-loading-spinner-dash 1.5s ease-in-out infinite, r-loading-spinner-color 6s ease-in-out infinite;\n    stroke-linecap: round;\n  }\n}\n\n@keyframes r-loading-spinner-rotate {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes r-loading-spinner-dash {\n  0% {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n  }\n\n  50% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -35px;\n  }\n\n  100% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -124px;\n  }\n}\n\n@keyframes r-loading-spinner-color {\n  100%,\n  0% {\n    stroke: var(--color-green-8);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
