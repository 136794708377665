// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.letter-badge {
  width: 12px;
  height: 12px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  line-height: 12px;
  border-radius: 3px;
  border: 1px solid var(--text-color-primary-3);
  margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./app/components/LetterBadge/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,6CAA6C;EAC7C,iBAAiB;AACnB","sourcesContent":[".letter-badge {\n  width: 12px;\n  height: 12px;\n  align-self: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 8px;\n  line-height: 12px;\n  border-radius: 3px;\n  border: 1px solid var(--text-color-primary-3);\n  margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
