import { useState, useEffect, useCallback } from 'react';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import mimeTypes from 'mime-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getImageFileSrc } from '../../utils/data-processing/file';

import './style.css';

interface ImageProps {
  className?: string;
  fitToScreen?: boolean;
  alt: string;
  fileType: string;
  fileSrc?: string;
  onFetch?: () => any;
}

const isImage = (mimeType: string | false | null): boolean => {
  if (!mimeType) {
    return false;
  }
  return mimeType.indexOf('image') !== -1;
};

const isPdf = (mimeType: string | false | null): boolean => {
  if (!mimeType) {
    return false;
  }
  return mimeType.indexOf('pdf') !== -1;
};

function Image(props: ImageProps) {
  const [state, setState] = useState({
    isLoading: !props.fileSrc,
    fileSrc: props.fileSrc
  });
  const [pdfPageCount, setPdfPageCount] = useState<number | null>(null);

  const baseClassName = 'r-image';
  let className = baseClassName;
  const mimeType = props.fileType ? mimeTypes.lookup(props.fileType) : null;

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.fitToScreen) {
    className += ` ${baseClassName}--fit-to-screen`;
  }

  useEffect(() => {
    (async () => {
      if (props.onFetch) {
        try {
          let result = null;
          result = await props.onFetch();
          const fileSrc = await getImageFileSrc(result, mimeType);

          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            fileSrc
          }));
        } catch (err) {
          setState((prevState) => ({
            ...prevState,
            isLoading: false
          }));
        }
      }
    })();
  }, [props.alt]);

  const onPdfLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setPdfPageCount(numPages);
  }, []);

  if (state.isLoading) {
    return <LoadingScreen className={`${baseClassName}__loading`} />;
  }

  if (isImage(mimeType)) {
    return <img className={className} alt={props.alt || 'N/A'} src={state.fileSrc} />;
  }

  if (isPdf(mimeType)) {
    return (
      <Document className={`${baseClassName}__pdf-container`} file={state.fileSrc} onLoadSuccess={onPdfLoadSuccess}>
        {Array.from(new Array(pdfPageCount), (_, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    );
  }

  return <span className={`${baseClassName}__no-preview-text`}>Preview not available.</span>;
}

export default Image;
