import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserIcon from '@components/deprecatedTookit/icons/fa/regular/UserIcon';
import MoneyBillIcon from '@components/deprecatedTookit/icons/fa/regular/MoneyBillIcon';
import BoxesIcon from '@components/deprecatedTookit/icons/fa/regular/BoxesIcon';
import ClockIcon from '@components/deprecatedTookit/icons/fa/regular/ClockIcon';
import WarehouseIcon from '@components/deprecatedTookit/icons/fa/regular/WarehouseIcon';
import MoneyBillAltIcon from '@components/deprecatedTookit/icons/fa/regular/MoneyBillAltIcon';
import FileInvoiceIcon from '@components/deprecatedTookit/icons/fa/regular/FileInvoiceIcon';
import Drawer from '../Drawer';
import SidebarNavLink from '../SidebarNavLink';
import history from '../../utils/history';

import './style.css';

function LeftSidebar(props) {
  const featureFlags = useFlags();
  const anySidebarOpen = props.arApOpen || props.open;
  const [state, setState] = useState({
    selectedNavLinkId: props.location.pathname
  });
  let contentClassName = 'left-sidebar__drawer-content';
  let overlayClassName = 'left-sidebar__drawer-overlay';

  state.selectedNavLinkId = props.location.pathname;

  if (!anySidebarOpen) {
    contentClassName += ' left-sidebar--hidden';
    overlayClassName += ' left-sidebar--hidden';
  }

  const handleNavLinkClick = (cProps) => {
    const path = cProps.id;

    setState({
      selectedNavLinkId: path
    });

    history.push(path);

    if (props.onClose) {
      props.onClose();
    }
  };

  const isNavLinkSelected = (path) => {
    const result = state.selectedNavLinkId.includes(path);

    return result;
  };

  let navItems;

  const showPayFormulaLink = featureFlags.pricingFormulaUpdate;
  const { bulkInvoiceCreation } = featureFlags;

  if (props.open) {
    navItems = (
      <>
        <div className="left-sidebar__group-title">Accounts</div>
        <SidebarNavLink
          value="Accounts"
          icon={<WarehouseIcon />}
          id={`${props.match.path}/settings/accounts`}
          selected={isNavLinkSelected(`${props.match.path}/settings/accounts`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="People"
          icon={<UserIcon />}
          id={`${props.match.path}/settings/people`}
          selected={isNavLinkSelected(`${props.match.path}/settings/people`)}
          onClick={handleNavLinkClick}
        />
        <div className="left-sidebar__group-title">Carrier</div>
        <SidebarNavLink
          value="Rating Rules"
          icon={<MoneyBillIcon />}
          id={`${props.match.path}/settings/carrier-rating-rules`}
          selected={isNavLinkSelected(`${props.match.path}/settings/carrier-rating-rules`)}
          onClick={handleNavLinkClick}
        />
        {showPayFormulaLink && (
          <SidebarNavLink
            value="Current Pricing"
            icon={<MoneyBillIcon />}
            id={`${props.match.path}/settings/pay-formulas`}
            selected={isNavLinkSelected(`${props.match.path}/settings/pay-formulas`)}
            onClick={handleNavLinkClick}
          />
        )}
        <SidebarNavLink
          value="Future Pricing"
          icon={<MoneyBillIcon />}
          id={`${props.match.path}/settings/future-pricing-formulas`}
          selected={isNavLinkSelected(`${props.match.path}/settings/future-pricing-formulas`)}
          onClick={handleNavLinkClick}
        />
        <div className="left-sidebar__group-title">AP Rules</div>
        <SidebarNavLink
          value="Accessorial"
          icon={<MoneyBillIcon />}
          id={`${props.match.path}/settings/ap-accessorial-fees`}
          selected={isNavLinkSelected(`${props.match.path}/settings/ap-accessorial-fees`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="Per Diem"
          icon={<MoneyBillAltIcon />}
          id={`${props.match.path}/settings/per-diem`}
          selected={isNavLinkSelected(`${props.match.path}/settings/per-diem`)}
          onClick={handleNavLinkClick}
        />

        {/* <SidebarNavLink
          value="Chassis Rule"
          icon={<MoneyBillIcon />}
          id={`${props.match.path}/settings/ap-chassis-fees`}
          selected={isNavLinkSelected(`${props.match.path}/settings/ap-chassis-fees`)}
          onClick={handleNavLinkClick}
        /> */}
        <div className="left-sidebar__group-title">AR Rules</div>
        <SidebarNavLink
          value="Accessorial"
          icon={<MoneyBillAltIcon />}
          id={`${props.match.path}/settings/ar-accessorial-fees`}
          selected={isNavLinkSelected(`${props.match.path}/settings/ar-accessorial-fees`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="Base Rate"
          icon={<MoneyBillAltIcon />}
          id={`${props.match.path}/settings/base-rates`}
          selected={isNavLinkSelected(`${props.match.path}/settings/base-rates`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="Chassis Fees"
          icon={<MoneyBillAltIcon />}
          id={`${props.match.path}/settings/chassis-fees`}
          selected={isNavLinkSelected(`${props.match.path}/settings/chassis-fees`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="Grace Period"
          icon={<MoneyBillAltIcon />}
          id={`${props.match.path}/settings/grace-period`}
          selected={isNavLinkSelected(`${props.match.path}/settings/grace-period`)}
          onClick={handleNavLinkClick}
        />
      </>
    );
  } else if (props.arApOpen) {
    navItems = (
      <>
        <div className="left-sidebar__group-title">Driver Pay</div>
        <SidebarNavLink
          value="Pending Pay"
          icon={<ClockIcon />}
          id={`${props.match.path}/ar-ap/driver-pay-pending`}
          selected={isNavLinkSelected(`${props.match.path}/ar-ap/driver-pay-pending`)}
          onClick={handleNavLinkClick}
        />
        <div className="left-sidebar__group-title">Customer Invoicing</div>
        <SidebarNavLink
          value="Waiting for Payment"
          icon={<ClockIcon />}
          id={`${props.match.path}/ar-ap/waiting-for-payment`}
          selected={isNavLinkSelected(`${props.match.path}/ar-ap/waiting-for-payment`)}
          onClick={handleNavLinkClick}
        />
        <SidebarNavLink
          value="Shipper Invoice"
          icon={<ClockIcon />}
          id={`${props.match.path}/ar-ap/shipper-invoice`}
          selected={isNavLinkSelected(`${props.match.path}/ar-ap/shipper-invoice`)}
          onClick={handleNavLinkClick}
        />
        {bulkInvoiceCreation && (
          <SidebarNavLink
            value="Bulk Invoice Generation"
            icon={<BoxesIcon />}
            id={`${props.match.path}/ar-ap/bulk-invoice-generation`}
            selected={isNavLinkSelected(`${props.match.path}/ar-ap/bulk-invoice-generation`)}
            onClick={handleNavLinkClick}
          />
        )}
        <div className="left-sidebar__group-title">Per Diem</div>
        <SidebarNavLink
          value="Actual Per Diem"
          icon={<FileInvoiceIcon />}
          id={`${props.match.path}/ar-ap/per-diem/actual-per-diem`}
          selected={isNavLinkSelected(`${props.match.path}/ar-ap/per-diem/actual-per-diem`)}
          onClick={handleNavLinkClick}
        />
      </>
    );
  }

  return (
    <Drawer
      anchor="left"
      contentClassName={contentClassName}
      overlayClassName={overlayClassName}
      open={anySidebarOpen}
      onClose={props.onClose}
    >
      {navItems}
    </Drawer>
  );
}

LeftSidebar.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  open: PropTypes.bool,
  arApOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default React.memo(LeftSidebar);
