import React from 'react';
import PropTypes from 'prop-types';
import InfoCircleIcon from '@components/deprecatedTookit/icons/fa/regular/InfoCircleIcon';
import ExclamationCircleIcon from '@components/deprecatedTookit/icons/fa/regular/ExclamationCircleIcon';
import ExclamationTriangleIcon from '@components/deprecatedTookit/icons/fa/regular/ExclamationTriangleIcon';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/regular/CheckCircleIcon';

export default function SystemToast(props) {
  const className = 'system-toast';
  let iconClassName = 'system-toast__icon';
  let Icon = null;

  switch (props.type) {
    case SystemToast.Type.INFO:
      Icon = InfoCircleIcon;
      iconClassName += ' system-toast__icon--info';
      break;
    case SystemToast.Type.ERROR:
      Icon = ExclamationCircleIcon;
      iconClassName += ' system-toast__icon--error';
      break;
    case SystemToast.Type.WARN:
      Icon = ExclamationTriangleIcon;
      iconClassName += ' system-toast__icon--warn';
      break;
    case SystemToast.Type.SUCCESS:
      Icon = CheckCircleIcon;
      iconClassName += ' system-toast__icon--success';
      break;
    default:
  }

  return (
    <article className={className}>
      {Icon && <Icon className={iconClassName} />}
      <p>{props.message}</p>
      {props.children && <div className="system-toast__actions">{props.children}</div>}
    </article>
  );
}

SystemToast.Type = {
  INFO: 'info',
  ERROR: 'error',
  WARN: 'warn',
  SUCCESS: 'success'
};

SystemToast.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.string,
  message: PropTypes.string
};
