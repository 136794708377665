// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-image--fit-to-screen {
        max-width: 100%;
        max-height: 100%;
    }
    .r-image__no-preview-text {
    color: white;
    font-weight: 500;
    font-size: 14px;
  }
    .r-image__pdf-container {
    max-height: 1000px;
    overflow-y: scroll;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Image/style.css"],"names":[],"mappings":"AACI;QACI,eAAe;QACf,gBAAgB;IACpB;IAEA;IACA,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB;IAEA;IACE,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".r-image {\n    &--fit-to-screen {\n        max-width: 100%;\n        max-height: 100%;\n    }\n\n    &__no-preview-text {\n    color: white;\n    font-weight: 500;\n    font-size: 14px;\n  }\n\n  &__pdf-container {\n    max-height: 1000px;\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
