// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-sidebar--hidden {
    display: none;
  }
  .left-sidebar__drawer-content {
    position: relative;
    flex-direction: column;
    min-width: 238px;
    background-color: #f7f8f9;
    box-shadow: 0 2px 2px 0 #bbbcbd;

    /* Forces drawer always open */
    transform: translate3d(0, 0, 0);
    z-index: 1;
  }
  @media (--viewport-min-lg) {
  .left-sidebar__drawer-content {
      /* display: flex;
      transform: translate3d(0, 0, 0); */
  }
    }
  .left-sidebar__drawer-overlay {
    display: none;
  }
  .left-sidebar__group-title {
      font-weight: 500;
      width: 100%;
      height: 49px;
      padding: 18px;
    }
`, "",{"version":3,"sources":["webpack://./app/components/LeftSideBar/style.css"],"names":[],"mappings":"AACE;IACE,aAAa;EACf;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,yBAAyB;IACzB,+BAA+B;;IAE/B,8BAA8B;IAC9B,+BAA+B;IAC/B,UAAU;EAMZ;EAJE;EAXF;MAYI;wCACkC;EAEtC;IADE;EAGF;IACE,aAAa;EACf;EAGE;MACE,gBAAgB;MAChB,WAAW;MACX,YAAY;MACZ,aAAa;IACf","sourcesContent":[".left-sidebar {\n  &--hidden {\n    display: none;\n  }\n\n  &__drawer-content {\n    position: relative;\n    flex-direction: column;\n    min-width: 238px;\n    background-color: #f7f8f9;\n    box-shadow: 0 2px 2px 0 #bbbcbd;\n\n    /* Forces drawer always open */\n    transform: translate3d(0, 0, 0);\n    z-index: 1;\n\n    @media (--viewport-min-lg) {\n      /* display: flex;\n      transform: translate3d(0, 0, 0); */\n    }\n  }\n\n  &__drawer-overlay {\n    display: none;\n  }\n\n  &__group {\n    &-title {\n      font-weight: 500;\n      width: 100%;\n      height: 49px;\n      padding: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
