import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

function TableBodyCell(props) {
  let className = 'r-table-body-cell';

  if (props.column.frozen === 'left') {
    className += ' r-table-body-cell-locked';

    if (props.columnIndex === props.columns.length - 1) {
      className += ' r-table-body-cell-locked--last';
    }
  }

  if (props.column.iconColumn) {
    className += ' r-table-body-cell-icon-cell';
  }

  if (props.isFirstField) {
    className += ' r-table-body-cell--first';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  return <div className={className}>{props.children}</div>;
}

TableBodyCell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  column: PropTypes.object,
  columnIndex: PropTypes.number,
  columns: PropTypes.array,
  isFirstField: PropTypes.bool,
  iconColumn: PropTypes.bool
};

export default TableBodyCell;
