// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneButton {
  margin: 0;
  display: flex;
  padding: 0;
  height: 14px;
  width: 14px;
  min-height: 14px;
  min-width: 14px;
  box-shadow: none;
  background: transparent;
  border: 0;
}

  .PhoneButton svg {
    height: 14px;
    width: 14px;
  }

  .PhoneButton:hover {
    color: var(--color-green-8);
  }

  .PhoneButton--disabled {
    color: var(--color-grayscale-5);
  }
`, "",{"version":3,"sources":["webpack://./app/components/PhoneButton/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,UAAU;EACV,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,SAAS;AAcX;;EAZE;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":[".PhoneButton {\n  margin: 0;\n  display: flex;\n  padding: 0;\n  height: 14px;\n  width: 14px;\n  min-height: 14px;\n  min-width: 14px;\n  box-shadow: none;\n  background: transparent;\n  border: 0;\n\n  & svg {\n    height: 14px;\n    width: 14px;\n  }\n\n  &:hover {\n    color: var(--color-green-8);\n  }\n\n  &--disabled {\n    color: var(--color-grayscale-5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
