import React from 'react';
import PropTypes from 'prop-types';

function PageActionBar(props) {
  let className = 'r-page-action-bar';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return <div className={className}>{props.children}</div>;
}

PageActionBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default PageActionBar;
