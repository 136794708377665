// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-detail-sidebar-sop-driver-instruction__multi-input-row {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 10px 0;
    max-width: 725px;
  }
  .department-detail-sidebar-sop-driver-instruction__multi-input-row > p {
    margin-bottom: 5px;
    color: #737980;
    font-size: 12px;
    font-weight: 500;
  }
  .department-detail-sidebar-sop-driver-instruction__multi-input-row > div {
    font-size: 14px;
  }
  .department-detail-sidebar-sop-driver-instruction__custom-input-switch {
    display: flex;
    align-items: center;
  }
  .department-detail-sidebar-sop-driver-instruction__custom-input-switch > div {
    margin-right: 15px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Instruction/style.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,cAAc;IACd,gBAAgB;EAClB;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;EAEA;IACE,eAAe;EACjB;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".department-detail-sidebar-sop-driver-instruction {\n  &__multi-input-row {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    margin: 10px 0;\n    max-width: 725px;\n  }\n\n  &__multi-input-row > p {\n    margin-bottom: 5px;\n    color: #737980;\n    font-size: 12px;\n    font-weight: 500;\n  }\n\n  &__multi-input-row > div {\n    font-size: 14px;\n  }\n\n  &__custom-input-switch {\n    display: flex;\n    align-items: center;\n  }\n\n  &__custom-input-switch > div {\n    margin-right: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
