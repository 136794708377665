import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileSelectZone from '../FileSelectZone';
import DropdownSelectInput from '../DropdownSelectInput';
import { createAttachment } from '../../state/resources/attachments/actions';

function NewAttachmentUploader(props) {
  const [state, setState] = useState({
    fileType: null,
    errors: null,
    isUploading: false,
    ddValues: [{ label: '', value: null }]
  });
  const baseClassName = 'app-file-select-zone';
  const className = baseClassName;

  const handleDropdownChange = (values, cProps) => {
    const fieldValue = values[0][cProps.valueField];

    setState((prevState) => ({
      ...prevState,
      ddValues: values,
      [cProps.dataField]: fieldValue
    }));
  };

  const clearState = (errors) => {
    setState((prevState) => {
      prevState.ddValues.pop();

      return {
        ...prevState,
        fileType: null,
        errors: errors || null,
        isUploading: false
      };
    });
  };

  const handleCloseFileSelectZone = async () => {
    clearState();
  };

  const handleFilesAccepted = async (acceptedFiles) => {
    let errors = [];

    try {
      if (!props.attachmentOwnerId) {
        const msg = 'Attachment owner id required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      if (!props.attachmentOwnerType) {
        const msg = 'Attachment owner type required.';
        errors.push({
          message: msg
        });

        throw new Error(msg);
      }

      setState((prevState) => ({
        ...prevState,
        errors,
        isUploading: true
      }));

      const formData = new FormData();
      const file = acceptedFiles[0];

      formData.append('file', file);
      formData.append('ownerType', props.attachmentOwnerType);
      formData.append('ownerUuid', props.attachmentOwnerId);
      formData.append('type', state.fileType);
      formData.append('name', file.name);

      const response = await createAttachment(formData)();

      if (props.onAttachmentCreated) {
        props.onAttachmentCreated(response.data);
      }
    } catch (err) {
      errors = err.errors ? errors.concat(err.errors) : errors;

      throw err;
    } finally {
      clearState(errors);
    }
  };

  return (
    <div className={className}>
      <div className={`${baseClassName}__dropdown-row`}>
        <div className={`${baseClassName}__dropdown-row-label`}>New Document:</div>
        <div className={`${baseClassName}__dropdown-row-dd`}>
          <DropdownSelectInput
            dropdownHandle
            options={props.dropdownOptions}
            values={state.ddValues}
            labelField="label"
            valueField="value"
            dataField="fileType"
            onChange={handleDropdownChange}
          />
        </div>
      </div>
      {state.fileType && (
        <FileSelectZone
          async
          isLoading={state.isUploading}
          className={`${baseClassName}__file-select-zone`}
          onClose={handleCloseFileSelectZone}
          onFilesAccepted={handleFilesAccepted}
        />
      )}
    </div>
  );
}

NewAttachmentUploader.propTypes = {
  attachmentOwnerId: PropTypes.string,
  attachmentOwnerType: PropTypes.string,
  dropdownOptions: PropTypes.array,
  onAttachmentCreated: PropTypes.func
};

export default NewAttachmentUploader;
