// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-sidebar__drawer-content {
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    flex-direction: column;
    background-color: #fff;
    z-index: var(--elevation-drawer);
  }

    .right-sidebar__drawer-content--open {
      box-shadow: 0 0 16px 0 rgb(0 0 0 / 24%);
    }
  .right-sidebar__drawer-overlay {
    top: var(--header-height);
    height: calc(100% - var(--header-height));
    z-index: var(--elevation-drawer);
  }
  .right-sidebar__group-title {
      font-weight: 500;
      width: 100%;
      height: 49px;
      padding: 18px;
    }
  .right-sidebar__close-btn {
    position: absolute;
    right: 0%;
    padding: 15px;
    cursor: pointer;
  }
  .right-sidebar__close-btn svg {
      height: 16px;
      color: #eaeaea;
    }
`, "",{"version":3,"sources":["webpack://./app/components/RightSidebar/style.css"],"names":[],"mappings":"AACE;IACE,yBAAyB;IACzB,0CAA0C;IAC1C,sBAAsB;IACtB,sBAAsB;IACtB,gCAAgC;EAKlC;;IAHE;MACE,uCAAuC;IACzC;EAGF;IACE,yBAAyB;IACzB,yCAAyC;IACzC,gCAAgC;EAClC;EAGE;MACE,gBAAgB;MAChB,WAAW;MACX,YAAY;MACZ,aAAa;IACf;EAGF;IACE,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,eAAe;EAMjB;EAJE;MACE,YAAY;MACZ,cAAc;IAChB","sourcesContent":[".right-sidebar {\n  &__drawer-content {\n    top: var(--header-height);\n    height: calc(100vh - var(--header-height));\n    flex-direction: column;\n    background-color: #fff;\n    z-index: var(--elevation-drawer);\n\n    &--open {\n      box-shadow: 0 0 16px 0 rgb(0 0 0 / 24%);\n    }\n  }\n\n  &__drawer-overlay {\n    top: var(--header-height);\n    height: calc(100% - var(--header-height));\n    z-index: var(--elevation-drawer);\n  }\n\n  &__group {\n    &-title {\n      font-weight: 500;\n      width: 100%;\n      height: 49px;\n      padding: 18px;\n    }\n  }\n\n  &__close-btn {\n    position: absolute;\n    right: 0%;\n    padding: 15px;\n    cursor: pointer;\n\n    & svg {\n      height: 16px;\n      color: #eaeaea;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
