import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import BaseTable from 'react-base-table';
import { createSelectAllTableCol } from './columns';

import 'react-base-table/styles.css';
import './style.css';

const defaultComponents = {
  ExpandIcon: () => null,
  SortIndicator: () => null,
  TableHeaderCell: () => null,
  TableCell: () => null
};

const defaultSort = (items, direction, dataGetter) => {
  if (items && dataGetter) {
    if (direction === '-') {
      items.sort((a, b) => {
        const valueA = dataGetter({ rowData: a });
        const valueB = dataGetter({ rowData: b });

        if (valueA === valueB) {
          return 0;
        }

        if (valueA > valueB) {
          return -1;
        }

        return 1;
      });
    } else {
      items.sort((a, b) => {
        const valueA = dataGetter({ rowData: a });
        const valueB = dataGetter({ rowData: b });

        if (valueA === valueB) {
          return 0;
        }

        if (valueA > valueB) {
          return 1;
        }

        return -1;
      });
    }
  }
};

function Table(props) {
  const [state, setState] = useState({
    data: props.data,
    sortBy: props.defaultSortBy
  });

  const prevData = useRef(props.data);

  if (props.data !== prevData.current.data) {
    prevData.current.data = props.data;
    state.data = props.data;
  }

  const className = 'r-table';
  let columns = null;
  let defaultDataGetter = null;

  if (props.columns) {
    // Merge user columns to default columns.
    if (props.hideSelectAll) {
      columns = props.columns;
    } else {
      columns = [createSelectAllTableCol(), ...props.columns];
    }

    let totalColumnsWidth = 0;

    // Compute last column width to be able to fill in the rest of the table.
    let flexColumnIndex;
    columns.forEach((item, index) => {
      totalColumnsWidth += item.width;

      if (item.flexGrow === 1) {
        flexColumnIndex = index;
      }

      if (state.sortBy && state.sortBy.indexOf(item.key) >= 0) {
        item.sortBy = state.sortBy;
        defaultDataGetter = item.dataGetter;
      }

      if (!state.data) {
        item.dataGetter = null;
      }
    });

    if (flexColumnIndex && totalColumnsWidth < props.width) {
      const flexColumn = columns[flexColumnIndex];

      flexColumn.width += props.width - totalColumnsWidth;
    }
  }

  const handleSort = (args) => {
    setState((prevState) => {
      const resultState = {
        ...prevState
      };

      let direction = null;

      if (resultState.sortBy) {
        direction = resultState.sortBy?.[0];
      }

      if (!resultState.sortBy || resultState.sortBy.indexOf(args.key) < 0) {
        resultState.sortBy = args.key;
      } else if (direction === '-') {
        resultState.sortBy = resultState.sortBy.substring(1);
      } else {
        resultState.sortBy = `-${resultState.sortBy}`;
      }

      // Reset other column sorting.
      columns.forEach((item) => {
        if (item.key !== args.key) {
          item.sortBy = null;
        } else {
          item.sortBy = resultState.sortBy;
        }
      });

      return resultState;
    });
  };

  // Initial sort
  defaultSort(state.data, state.sortBy && state.sortBy[0], defaultDataGetter);

  return (
    <BaseTable
      {...props}
      data={state.data}
      className={className}
      overlayRenderer={null}
      components={defaultComponents}
      columns={columns}
      onColumnSort={handleSort}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  width: PropTypes.number,
  defaultSortBy: PropTypes.string,
  hideSelectAll: PropTypes.bool,
  fixed: PropTypes.bool,
  height: PropTypes.number,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  frozenData: PropTypes.array
};

export default Table;
