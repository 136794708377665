// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-nav-link {
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px 17px;
  color: var(--color-grayscale-8);
  cursor: pointer;
  font-weight: 500;
}

  .sidebar-nav-link__icon-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar-nav-link:hover {
    background-color: var(--color-grayscale-1);
  }

  .sidebar-nav-link--idle > .sidebar-nav-link__selected-flag {
      background-color: var(--color-green-6);
    }

  .sidebar-nav-link--idle:hover > .sidebar-nav-link__selected-flag {
        transform: translate3d(-100%, 0, 0);
      }

  .sidebar-nav-link--selected {
    background-color: var(--color-grayscale-0);
  }

  .sidebar-nav-link--selected:hover {
      background-color: var(--color-grayscale-0);
    }

  .sidebar-nav-link--selected > .sidebar-nav-link__selected-flag {
      background-color: var(--color-green-8);
      transform: translate3d(-100%, 0, 0);
    }

  .sidebar-nav-link--selected svg {
      color: var(--color-green-8);
    }

  .sidebar-nav-link svg {
    height: 100%;
    height: 14px;
    margin-left: 14px;
  }

  .sidebar-nav-link__value {
    margin-left: 14px;
  }

  .sidebar-nav-link__selected-flag {
    position: absolute;
    top: 0;
    left: 100%;
    width: 6px;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`, "",{"version":3,"sources":["webpack://./app/components/SidebarNavLink/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;AA2DlB;;EAzDE;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,0CAA0C;EAC5C;;EAGE;MACE,sCAAsC;IACxC;;EAGE;QACE,mCAAmC;MACrC;;EAIJ;IACE,0CAA0C;EAc5C;;EAZE;MACE,0CAA0C;IAC5C;;EAEA;MACE,sCAAsC;MACtC,mCAAmC;IACrC;;EAEA;MACE,2BAA2B;IAC7B;;EAGF;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,UAAU;IACV,YAAY;IACZ,qDAAqD;EACvD","sourcesContent":[".sidebar-nav-link {\n  position: relative;\n  display: flex;\n  width: 100%;\n  padding: 15px 17px;\n  color: var(--color-grayscale-8);\n  cursor: pointer;\n  font-weight: 500;\n\n  &__icon-wrap{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &:hover {\n    background-color: var(--color-grayscale-1);\n  }\n\n  &--idle {\n    & > .sidebar-nav-link__selected-flag {\n      background-color: var(--color-green-6);\n    }\n\n    &:hover {\n      & > .sidebar-nav-link__selected-flag {\n        transform: translate3d(-100%, 0, 0);\n      }\n    }\n  }\n\n  &--selected {\n    background-color: var(--color-grayscale-0);\n\n    &:hover {\n      background-color: var(--color-grayscale-0);\n    }\n\n    & > .sidebar-nav-link__selected-flag {\n      background-color: var(--color-green-8);\n      transform: translate3d(-100%, 0, 0);\n    }\n\n    & svg {\n      color: var(--color-green-8);\n    }\n  }\n\n  & svg {\n    height: 100%;\n    height: 14px;\n    margin-left: 14px;\n  }\n\n  &__value {\n    margin-left: 14px;\n  }\n\n  &__selected-flag {\n    position: absolute;\n    top: 0;\n    left: 100%;\n    width: 6px;\n    height: 100%;\n    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
