// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-info-wrapper {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 65px;
  cursor: pointer;
}

.profile-info-right {
  padding-left: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-info-right > p {
    font-size: 14px;
    margin: 0;
  }

.profile-picture {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

.profile-picture--default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    width: 38px;
    height: 38px;
    background-color: #CDDBE1;
  }

.profile-picture--default > p {
      line-height: normal;
      vertical-align: middle;
      text-align: center;
      padding-top: 1px;
      color: white;
      font-weight: 600;
      font-size: 18px;
    }

.profile-info-fullname {
  font-weight: 600;
  text-transform: capitalize;
}

.version-info {
  color: #767676;
}
`, "",{"version":3,"sources":["webpack://./app/components/ProfileInfo/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AAMzB;;AAJE;IACE,eAAe;IACf,SAAS;EACX;;AAGF;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AAqBrB;;AAnBE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,yBAAyB;EAW3B;;AATE;MACE,mBAAmB;MACnB,sBAAsB;MACtB,kBAAkB;MAClB,gBAAgB;MAChB,YAAY;MACZ,gBAAgB;MAChB,eAAe;IACjB;;AAIJ;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".profile-info-wrapper {\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin-right: 65px;\n  cursor: pointer;\n}\n\n.profile-info-right {\n  padding-left: 11px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  & > p {\n    font-size: 14px;\n    margin: 0;\n  }\n}\n\n.profile-picture {\n  width: 38px;\n  height: 38px;\n  border-radius: 100%;\n\n  &--default {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    border-radius: 100%;\n    width: 38px;\n    height: 38px;\n    background-color: #CDDBE1;\n\n    > p {\n      line-height: normal;\n      vertical-align: middle;\n      text-align: center;\n      padding-top: 1px;\n      color: white;\n      font-weight: 600;\n      font-size: 18px;\n    }\n  }\n}\n\n.profile-info-fullname {\n  font-weight: 600;\n  text-transform: capitalize;\n}\n\n.version-info {\n  color: #767676;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
