import React, { useState, useRef } from 'react';

import './style.css';

interface SidebarNavLinkProps {
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  id?: any;
  selected?: boolean;
  value: any;
  icon: any;
  onClick: (props: SidebarNavLinkProps, event: any) => void;
  disableDefaultSelectedStyle?: boolean;
}

function SidebarNavLink(props: SidebarNavLinkProps) {
  const prevPropSelected = useRef(props.selected);
  const [state, setState] = useState({
    selected: props.selected
  });

  if (prevPropSelected.current !== props.selected) {
    prevPropSelected.current = props.selected;
    state.selected = props.selected;
  }

  const handleClick = (event: any) => {
    setState({
      ...state,
      selected: true
    });

    if (props.onClick) {
      props.onClick(props, event);
    }
  };

  let className = 'sidebar-nav-link';
  // let selectedFlagClassName = 'sidebar-nav-link__selected-flag';
  const valueClassName = 'sidebar-nav-link__value';
  const iconWrapClassName = 'sidebar-nav-link__icon-wrap';

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (!props.disableDefaultSelectedStyle) {
    if (state.selected) {
      className += ' sidebar-nav-link--selected';
    } else {
      className += ' sidebar-nav-link--idle';
    }
  }

  return (
    <div className={className} onClick={handleClick}>
      <span className={iconWrapClassName}>{props.icon}</span>
      <span className={valueClassName}>{props.value}</span>
      {/* <div className={selectedFlagClassName} /> */}
    </div>
  );
}

export default SidebarNavLink;
