// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --sidebar-header-height: 87px;
}

.right-sidebar-header__title {
    font-size: 20px;
    line-height: 32px;
    color: #8e8e93;
    flex-grow: 1;
  }

.right-sidebar-header__top {
    display: flex;
  }

.right-sidebar-header__subtitle {
    font-size: 24px;
    line-height: 33px;
    flex-grow: 1;
    font-weight: 500;
    color: var(--theme-1-color-0);
  }

.right-sidebar-header__right {
    display: flex;
  }

.right-sidebar-header__right-text {
      color: #8e8e93;
      line-height: 32px;
    }

.right-sidebar-header__right-icon {
      height: 33px;
      display: flex;
      align-items: center;
    }

.right-sidebar-header__wrapper {
    display: flex;
    flex-direction: column;
    padding: 11px 30px;
    background-color: var(--theme-1-bg-color-0);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    z-index: 1000;
    height: var(--sidebar-header-height);
  }`, "",{"version":3,"sources":["webpack://./app/components/SidebarHeader/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAGE;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;EACd;;AAEA;IACE,aAAa;EACf;;AAEA;IACE,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;EAC/B;;AAEA;IACE,aAAa;EAYf;;AAVE;MACE,cAAc;MACd,iBAAiB;IACnB;;AAEA;MACE,YAAY;MACZ,aAAa;MACb,mBAAmB;IACrB;;AAGF;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,2CAA2C;IAC3C,wCAAwC;IACxC,aAAa;IACb,oCAAoC;EACtC","sourcesContent":[":root {\n  --sidebar-header-height: 87px;\n}\n\n.right-sidebar-header {\n  &__title {\n    font-size: 20px;\n    line-height: 32px;\n    color: #8e8e93;\n    flex-grow: 1;\n  }\n\n  &__top {\n    display: flex;\n  }\n\n  &__subtitle {\n    font-size: 24px;\n    line-height: 33px;\n    flex-grow: 1;\n    font-weight: 500;\n    color: var(--theme-1-color-0);\n  }\n\n  &__right {\n    display: flex;\n\n    &-text {\n      color: #8e8e93;\n      line-height: 32px;\n    }\n\n    &-icon {\n      height: 33px;\n      display: flex;\n      align-items: center;\n    }\n  }\n\n  &__wrapper {\n    display: flex;\n    flex-direction: column;\n    padding: 11px 30px;\n    background-color: var(--theme-1-bg-color-0);\n    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);\n    z-index: 1000;\n    height: var(--sidebar-header-height);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
