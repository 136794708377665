import React, { useContext, useCallback } from 'react';
import { appUIActionContext, appUIStateContext } from '../App/contexts';
import Component from './component';
import { closeRightSidebar } from '../../state/ui/actions';

function RightSidebarConsumer() {
  const executeAppUIAction = useContext(appUIActionContext);
  const appUIstate = useContext(appUIStateContext);
  const opts = appUIstate['ui.rightSidebar.props'];

  const handleClose = useCallback(() => {
    executeAppUIAction(closeRightSidebar());
  }, []);

  return (
    <Component
      open={appUIstate['ui.rightSidebar.open']}
      component={appUIstate['ui.rightSidebar.component']}
      disabled={appUIstate['app.modal.open']}
      {...opts}
      onClose={handleClose}
    />
  );
}

export default React.memo(RightSidebarConsumer);
