import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { getAuthToken } from '../../utils/auth';
import createLoginRedirectURL from '../../utils/create-login-redirect-url';

function PrivateRoute(props) {
  const authToken = getAuthToken();

  if (authToken) {
    return <Route {...props} component={props.component} />;
  }

  const redirectURL = createLoginRedirectURL('hub');
  window.location.assign(redirectURL);

  return null;
}

PrivateRoute.propTypes = {
  redirectTo: PropTypes.string,
  component: PropTypes.any,
  path: PropTypes.string
};

export default PrivateRoute;
