import React from 'react';
import moment from 'moment';
import { v1 as uuidV1 } from 'uuid';
import GlobeAmericasIcon from '@components/deprecatedTookit/icons/fa/regular/GlobeAmericasIcon';
import TableHeaderFieldCol from './components/TableHeaderFieldCol';
import TableBodyCell from './components/TableBodyCell';
import Checkbox from '../Checkbox';
import EditableField from '../EditableField';
import { stopTypesToUIMappings } from '../../constants';
import { isReserved } from '../../utils/reserved-entities';

const firstFieldPaddingLeft = 30;
const paddingRight = 25;

// Univeral create column function.
// Use this.
export const createCol = (opts = {}) => {
  const width = (opts.width ? opts.width : 10) + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const { cellRenderer } = opts;
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || uuidV1(),
    width,
    frozen: opts.frozen,
    sortable: opts.sortable || false,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable={opts.searchable}
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
        className={opts.headerClassName}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args}>
        {cellRenderer ? (
          cellRenderer(args)
        ) : (
          <span className={opts.className ? opts.className : undefined}>{args.cellData}</span>
        )}
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (cellRenderer) {
    delete opts.cellRenderer;
  }

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

// *************************************************************************************
// IMPORTANT: DO NOT CREATE ANYMORE OF THESE BELOW
// *************************************************************************************

export const createActionsTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'phone',
    width: 90 + paddingRight,
    headerRenderer: () => null,
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-phone">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createSelectAllTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    frozen: 'left',
    key: 'select-all',
    width: 14 + firstFieldPaddingLeft + paddingRight,
    headerRenderer: (args) => (
      <TableHeaderFieldCol className="r-table-col-cell__select" isFirstField name={args.column.key}>
        <Checkbox className="r-table-col-cell__select-checkbox" />
      </TableHeaderFieldCol>
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table-col-cell__select" />
    )
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createFirstColBuffer = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    frozen: 'left',
    key: 'select-all',
    width: 25,
    headerRenderer: (args) => (
      <TableHeaderFieldCol className="r-table-col-cell__select" isFirstField name={args.column.key} />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table-col-cell__select" />
    )
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createNameTableCol = (opts = {}) => {
  const width = 140 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);

  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    frozen: opts.frozen,
    key: 'name',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable
        isFirstField={args.column.isFirstOfGroup}
        title="Name"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
        filter-data-testid="name-filter"
      />
    ),
    cellRenderer: (args) => {
      const isUser = args.rowData.type === 'user';
      return (
        <TableBodyCell
          isFirstField={args.column.isFirstOfGroup}
          {...args}
          className="r-table__cell-col-name"
          key={`name-${isUser ? args.rowData.id : args.rowData.uuid}`}
        >
          <span
            className="r-table__link"
            onClick={() => opts.onClickRef.current(isUser ? args.rowData.id : args.rowData.uuid)}
          >
            {args.cellData}
          </span>
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createPeopleTableCol = (opts = {}) => {
  const width = (opts.width || 140) + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);

  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    frozen: opts.frozen,
    key: opts.key || 'name',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell
        isFirstField={args.column.isFirstOfGroup}
        {...args}
        className="r-table__cell"
        iconColumn={opts.iconColumn}
      >
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createDepartmentNameTableCol = (opts = {}) => {
  const width = 180 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);

  const options = {
    onCellClickRef: opts.onCellClickRef,
    isFirstOfGroup: opts.isFirstOfGroup,
    frozen: opts.frozen,
    key: opts.key || 'name',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable={opts.searchable}
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title || 'Department Name'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-name">
        {args.column.onCellClickRef ? (
          <span className="r-table__link" onClick={() => args.column.onCellClickRef.current(args.rowData.id)}>
            {args.cellData}
          </span>
        ) : (
          <span> {args.cellData}</span>
        )}
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createDepartmentTypeTableCol = (opts = {}) => {
  const width = 150 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || 'type',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title || 'Type'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        onFilterChange={opts.onFilterChange}
        filterValue={opts.filterValue}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-company">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createStopTypeTableCol = (opts = {}) => {
  const width = 100 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || 'type',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable={opts.searchable}
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title || 'Type'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        onFilterChange={opts.onFilterChange}
        filterValue={opts.filterValue}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-company">
        <span>{stopTypesToUIMappings[args.cellData]}</span>
        {/* <EditableField
            type="dropdown"
            editable
            dataId={args.rowData.id}
            dataField={args.column.key}
            dataResourceType={opts.resourceType}
            value={args.cellData}
            inputProps={{
              dropdownHandle: true,
              options: stopTypesUIDropdownOptions
            }}
            displayFormatter={(value) => {
              return stopTypesToUIMappings[value];
            }}
            onChange={opts.onDataChange}
          /> */}
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createContactTableCol = (opts = {}) => {
  const width = 125 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || 'contactNumber',
    width,
    sortable: opts.sortable || false,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable={opts.searchable}
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title || 'Contact Number'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-city">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createCompanyTableCol = (opts = {}) => {
  const width = 150 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'company',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable
        isFirstField={args.column.isFirstOfGroup}
        title="Company"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        onFilterChange={opts.onFilterChange}
        filterValue={opts.filterValue}
        filter-data-testid="company-filter"
      />
    ),
    cellRenderer: (args) => {
      const companyId = args.rowData.companyUUID || args.rowData.company?.uuid;
      return (
        <TableBodyCell
          isFirstField={args.column.isFirstOfGroup}
          {...args}
          className="r-table__cell-col-company"
          key={`company-${companyId}`}
        >
          <span className="r-table__link" onClick={() => opts.onClick(companyId)}>
            {args.cellData}
          </span>
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };
  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createCityTableCol = (opts = {}) => {
  const width = 125 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || 'city',
    width,
    sortable: opts.sortable || false,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable={opts.searchable}
        isFirstField={args.column.isFirstOfGroup}
        title={opts.title || 'City'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-city">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createDepartmentTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'department',
    width: 200 + paddingRight,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Account"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell
        isFirstField={args.column.isFirstOfGroup}
        {...args}
        className="r-table__cell-col-department"
        key={`department-${args.rowData.uuid}`}
      >
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createRoleTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'role',
    width: 100 + paddingRight,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Role"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-role">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }
  return options;
};

export const createEmailTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'email',
    width: 200 + paddingRight,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable
        title="Email"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={opts.filterValue}
        onFilterChange={opts.onFilterChange}
        filter-data-testid="email-filter"
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell
        isFirstField={args.column.isFirstOfGroup}
        {...args}
        className="r-table__cell-col-email"
        key={`email-${args.cellData}`}
      >
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }
  return options;
};

export const createPhoneTableCol = (opts = {}) => {
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'phone',
    width: 90 + paddingRight,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        searchable
        title="Phone"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        onFilterChange={opts.onFilterChange}
        filter-data-testid="phone-filter"
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell
        isFirstField={args.column.isFirstOfGroup}
        {...args}
        className="r-table__cell-col-phone"
        key={`phone-${args.cellData}`}
      >
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createRegionTableCol = (opts = {}) => {
  const width = 115 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'region',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Region"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={args.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-region">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createImportApptTableCol = (opts = {}) => {
  const width = 110 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'importAppt',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Import Appt"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-import-appt">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createExportApptTableCol = (opts = {}) => {
  const width = 110 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'exportAppt',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Export Appt"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
      />
    ),
    cellRenderer: (args) => (
      <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell-col-export-appt">
        <span>{args.cellData}</span>
      </TableBodyCell>
    ),
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createBannedCol = (opts = {}) => {
  const width = 70 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'banned',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Banned"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={args.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => {
      let className = 'r-table__cell-col-banned';

      if (!opts.editable) {
        className += ' r-table__cell--not-editable';
      }

      return (
        <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className={className}>
          <Checkbox
            disabled={isReserved(args.rowData.uuid)}
            dataId={args.rowData.uuid}
            checked={args.rowData && args.rowData && args.rowData.suspendedUntil === '9999-09-09T16:09:00.000Z'}
            onToggle={(value) => {
              if (value) {
                opts.onDataChange('9999-09-09T16:09:00.000Z', {
                  dataId: args.rowData.uuid,
                  dataResourceType: 'department',
                  type: 'date-time',
                  dataField: 'suspendedUntil',
                  value: '9999-09-09T16:09:00.000Z'
                });
              } else {
                opts.onDataChange(null, {
                  dataId: args.rowData.uuid,
                  dataResourceType: 'department',
                  type: 'date-time',
                  dataField: 'suspendedUntil',
                  value: null
                });
              }
            }}
          />
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createSuspendUntilTableCol = (opts = {}) => {
  const width = 110 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: 'suspendedUntil',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title="Suspend Until"
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={args.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => {
      let className = 'r-table__cell-col-suspend-until';

      if (!opts.editable) {
        className += ' r-table__cell--not-editable';
      }

      if (args.rowData && args.rowData.suspendedUntil === '9999-09-09T16:09:00.000Z') {
        className += ' r-table__cell--disabled';
      }

      return (
        <TableBodyCell
          isFirstField={args.column.isFirstOfGroup}
          {...args}
          key={`suspended-until-department-${args.rowData.uuid}`}
          className={className}
        >
          {isReserved(args.rowData.uuid) ? (
            args.rowData.suspendedUntil
          ) : (
            <EditableField
              type="date-time"
              inputProps={{
                timeFormat: 'HH:mm',
                disableRange: true
              }}
              editable={args.rowData && args.rowData.suspendedUntil !== '9999-09-09T16:09:00.000Z'}
              dataId={args.rowData.uuid}
              dataField={args.column.key}
              dataResourceType={opts.dataResourceType || args.rowData.type}
              value={args.cellData && args.cellData}
              displayFormatter={(value) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : '')}
              onSave={opts.onDataChange}
            />
          )}
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createDateTimeTableCol = (opts = {}) => {
  const width = 280 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key || 'date',
    width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        isFirstField={opts.isFirstOfGroup}
        title={opts.title || 'Date'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={args.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => {
      let className = 'r-table__cell-col-date-time';

      if (!opts.editable) {
        className += ' r-table__cell--not-editable';
      }

      return (
        <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className={className}>
          <EditableField
            type="date-time"
            inputProps={{
              timeFormat: 'HH:mm',
              disableRange: true
            }}
            editable={opts.editable}
            dataFieldType="date"
            dataId={args.rowData.id}
            dataField={args.column.key}
            dataResourceType={opts.resourceType}
            value={args.cellData && args.cellData}
            displayFormatter={(value) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : '')}
            onSave={opts.onDataChange}
          />
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};

export const createBaseRateTableCol = (opts = {}) => {
  const width = 80 + paddingRight + (opts.isFirstField ? firstFieldPaddingLeft : 0);
  const options = {
    isFirstOfGroup: opts.isFirstOfGroup,
    key: opts.key,
    width: opts.width || width,
    sortable: true,
    headerRenderer: (args) => (
      <TableHeaderFieldCol
        title={opts.title || 'N/A'}
        sortable={args.column.sortable}
        name={args.column.key}
        sortBy={args.column.sortBy}
        filterValue={args.filterValue}
        onFilterChange={opts.onFilterChange}
      />
    ),
    cellRenderer: (args) => {
      const className = 'r-table__cell-col-money';
      const { key } = args.column;
      const isGlobal = args.rowData[key].global;

      return (
        <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className={className}>
          {isGlobal && <GlobeAmericasIcon className="r-table__cell-col-money-globe-icon" />}
          <EditableField
            type="text"
            editable={opts.editable}
            dataId={args.rowData.id}
            dataField={key}
            dataResourceType={opts.resourceType}
            value={args.cellData && args.cellData}
            displayFormatter={(value) => value}
            onSave={opts.onDataChange}
          />
        </TableBodyCell>
      );
    },
    dataGetter: opts.dataGetter
  };

  if (opts) {
    return {
      ...options,
      ...opts
    };
  }

  return options;
};
