// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-b-modal {
  position: absolute;
  inset: 0;
  z-index: 9999;
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
  background: #000a;
  transform: scale3d(1, 0, 1);

  /* Add iOS momentum scrolling. */
  --webkit-overflow-scrolling: 'touch';
}

  .r-b-modal--show {
    transform: scale3d(1, 1, 1);
  }

  .r-b-modal--fullscreen {
    position: fixed;
    inset: 0;
  }

  .r-b-modal__button {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 2rem;
    border: solid thin #fff;
    color: #fff;
    padding: 7px 15px;
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./app/components/Modal/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,2BAA2B;;EAE3B,gCAAgC;EAChC,oCAAoC;AAqBtC;;EAnBE;IACE,2BAA2B;EAC7B;;EAEA;IACE,eAAe;IACf,QAAQ;EACV;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,eAAe;IACf,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".r-b-modal {\n  position: absolute;\n  inset: 0;\n  z-index: 9999;\n  display: flex;\n  max-width: 100vw;\n  max-height: 100vh;\n  overflow: auto;\n  background: #000a;\n  transform: scale3d(1, 0, 1);\n\n  /* Add iOS momentum scrolling. */\n  --webkit-overflow-scrolling: 'touch';\n\n  &--show {\n    transform: scale3d(1, 1, 1);\n  }\n\n  &--fullscreen {\n    position: fixed;\n    inset: 0;\n  }\n\n  &__button {\n    position: absolute;\n    right: 5%;\n    top: 5%;\n    font-size: 2rem;\n    border: solid thin #fff;\n    color: #fff;\n    padding: 7px 15px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
