import React, { useContext } from 'react';
import { appContext } from '../App/contexts';
import Component from './component';

function LeftSidebarConsumer(props) {
  const { state } = useContext(appContext);

  return (
    <Component
      open={state['ui.leftSidebar.open']}
      arApOpen={state['ui.leftSidebarForArAp.open']}
      component={state['ui.leftSidebar.component']}
      {...props}
    />
  );
}

export default LeftSidebarConsumer;
